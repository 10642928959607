.nested-list {
    font-family: Arial, sans-serif;
    color: #333;
    font-size: 14px;
    width: 350px;
  }
  
  .node-header,
  .node .nested-menu .node-header {
    display: flex;
    align-items: center;
    padding: 4px 0;
  }
  
  .nested-menu {
    padding-left: 16px;
  }
  
  .toggle-button {
    border: none;
    background: none;
    cursor: pointer;
    color: #007BFF;
  }
  
/* Hide the default checkbox appearance */
input[type="checkbox"].partial-selection,
input[type="checkbox"].general-selection {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Style the label to look like a checkbox */
input[type="checkbox"].partial-selection + label::before,
input[type="checkbox"].general-selection + label::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #007BFF; /* Blue border */
  border-radius: 4px; /* Rounded corners like a checkbox */
  background-color: white;
  margin-right: 5px;
  vertical-align: middle;
  position: relative;
}

/* Checked state styles */
input[type="checkbox"].general-selection:checked + label::before {
  background-color: #007BFF; /* Dark blue background */
  border-color: #007BFF; /* Dark blue border */
}

input[type="checkbox"].general-selection:checked + label::before::after {
  content: '✔'; /* Check mark */
  display: block;
  color: white; /* White check mark */
  font-size: 14px; /* Adjust size as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Indeterminate (partial) state styles */
input[type="checkbox"].partial-selection + label::before {
  background-color: rgba(0, 123, 255, 0.3); /* Light transparent blue background */
  border-color: #007BFF; /* Blue border */
}

input[type="checkbox"].partial-selection + label::before::after {
  content: '◯'; /* Circle */
  display: block;
  color: #007BFF; /* Dark blue circle */
  font-size: 14px; /* Adjust size as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  