.selection-panel {
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
  }
  
  .header-section {
    padding: 10px;
    background-color: #e9ecef; /* Optional: Background color for the header */
    border-bottom: 1px solid #ddd; /* Optional: Border to separate the header */
  }
  
  .list-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Ensure no overflow outside the wrapper */
  }
  
  .list-section {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .button-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    background-color: #f8f9fa; /* Match the right panel background color */
    border-top: 1px solid #ddd; /* Optional: Border to separate the buttons */
    margin-bottom: 20px;
  }
  
  .button-section button {
    width: 90%;
    margin: 5px 0;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  