.app {
    display: flex;
    height: 100vh; /* Full viewport height */
  }
  
  /* The main content area which will occupy the remaining space */
  .left-panel {
    flex: 1; /* This will allow the left panel to take up the remaining space */
    margin-right: 350px;
    height: 100vh; /* Full viewport height */
    overflow-y: hidden; /* Disable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
    background-color: #f8f9fa; /* Optional: Background color */
    padding: 10px; /* Optional: Padding */
    border-right: 1px solid #ddd; /* Optional: Border to separate panels */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    display: flex;
    flex-direction: column;
  }
  
  /* The right panel which is fixed at 300px width */
  .right-panel {
    width: 350px; /* Fixed width */
    height: 100vh; /* Full viewport height */
    overflow-y: hidden; /* Disable vertical scrolling */
    position: fixed; /* Fix position */
    top: 0;
    right: 0; /* Anchored to the right */
    background-color: #f8f9fa; /* Optional: Background color */
    padding: 20px; /* Optional: Padding */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    border-left: 1px solid #ddd; /* Optional: Border to separate panels */
  }
  
  